<script>
import { Line } from "vue-chartjs";
// const { reactiveProp, reactiveData } = mixins;

export default {
  name: "TransactionsChart",
  extends: Line,
  // mixins: [reactiveProp, reactiveData],
  props: ['chartData', 'label', 'xAxesScale'],
  data() {
    return {
      gradient: null
    }
  },
  mounted() {
    this.renderLineChart();
  },
  methods: {
    renderLineChart() {
      this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
      this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
      this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");
      // Overwriting base render method with actual data.
      this.renderChart({
        labels: this.label,
        datasets: [
          {
            label: "Transaction",
            lineTension: 0,
            backgroundColor: "transparent",
            borderColor: '#492540',
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointRadius: 0,
            spanGaps: true,
            // backgroundColor: this.gradient,
            data: this.chartData,
          },
        ],
      },
      {
        responsive: true, 
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          intersect: false,
          mode: 'nearest',
          axis: 'x'
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              time: {
                unit: this.xAxesScale
              }
            }
          ]
        }
      });
    }
  },
  watch: {
    xAxesScale: function() {
      this.renderLineChart();
      // this.$data._chart.update();
    },
    chartData: function() {
      this.renderLineChart();
    }
  }
};
</script>