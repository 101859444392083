<template>
  <div>
    <h3>Merchant Dashboard</h3>
    <vs-row class="mb-4 mt-4">
      <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
            class="p-0 pb-3"
          >
            <div class="flat-card text-center h-100 pb-4">
                <div class="d-flex justify-content-center">
                  <div class="top-icon d-flex align-items-center justify-content-center">
                      <!-- <i class="material-icons" style="font-size:25px;color:green" @click="goBack()">check_circle</i> -->
                      <vs-icon icon="check_circle" color="green"></vs-icon>
                  </div>
                </div>
              <h5 class="mt-3">Test Mode</h5>
              <h6 class="font-weight-normal text-muted mt-3">Try out payments and transactions in test mode and view them on the dashboard</h6>
            </div>
          </vs-col>
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
            class="p-0 pb-3 responsive-margin"
          >
            <div class="flat-card text-center h-100 pb-4">
              <div class="d-flex justify-content-center">
                  <div class="top-icon d-flex align-items-center justify-content-center">
                      <!-- <i class="material-icons" style="font-size:25px" @click="goBack()">lock</i> -->
                      <vs-icon :icon="isAccountActivated ? 'check_circle' : 'error'" :color="isAccountActivated ? 'green' : 'grey'"></vs-icon>
                  </div>
                </div>
              <h5 class="mt-3">Account Activation</h5>
              <h6 class="font-weight-normal text-muted mt-3">Activate your business and enjoy the full benefits Utupay has to offer</h6>
              <vs-button v-if="!isAccountActivated" class="mt-3" @click="activate">Activate now</vs-button>
            </div>
          </vs-col>
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
            class="p-0 pb-3"
          >
            <div class="flat-card text-center h-100 pb-4">
              <div class="d-flex justify-content-center">
                  <div class="top-icon d-flex align-items-center justify-content-center">
                      <!-- <i class="material-icons" style="font-size:25px" @click="goBack()">lock</i> -->
                      <vs-icon :icon="isLive ? 'check_circle' : 'error'" :color="isLive ? 'green' : 'grey'"></vs-icon>
                  </div>
                </div>
              <h5 class="mt-3">Go Live</h5>
              <h6 class="font-weight-normal text-muted mt-3">Go live and start receiving real payments from your customers</h6>
            </div>
          </vs-col>
    </vs-row>
    <div class="mt-4">
      <vs-card class="cardx mt-4 p-2">
        <div slot="header" class="pb-5">
          <div class="float-left">
            <h3>Transactions Summary</h3>
            <h5 class="text-muted font-weight-normal mt-1">
              Overview of This Month - {{month}}
            </h5>
          </div>
          <div class="float-right d-none d-md-block">
            <ChartDuration :currentScale="dashboard.scale"/>
          </div>
        </div>
        <EarningStat />
        <hr class="mt-4" />
        <vs-row class="mt-4">
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="12"
            code-toggler
          >
            <DashboardInfo
              icon="account_balance_wallet"
              :text="walletBalance"
              title="Wallet Balance"
              color="#F26D5B"
            />
          </vs-col>
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="12"
            code-togg1ler
          >
            <DashboardInfo
              icon="account_balance"
              :text="totalIncome"
              title="Total Income"
              color="#F26D5B"
            />
          </vs-col>
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="12"
            code-toggler
          >
            <DashboardInfo
              icon="payments"
              :text="totalSettlement"
              title="Total Settlements"
              color="#F26D5B"
            />
          </vs-col>
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="12"
            code-toggler
          >
            <DashboardInfo
              icon="people_alt"
              :text="dashboard.total_customers"
              title="Total Customers"
              color="#F26D5B"
            />
          </vs-col>
        </vs-row>
      </vs-card>
    </div>
    <div class="mt-4 mb-2">
      <h4 class="text-muted">Payment Insights</h4>
    </div>
    <div>
      <vs-card class="cardx p-2">
        <div slot="header" class="pb-5">
          <div class="float-left">
            <h3>Payment Volume</h3>
            <h5 class="text-muted font-weight-normal mt-1">
              Categorized by payment method
            </h5>
          </div>
          <div class="float-right d-none d-md-block">
            <ChartDuration :currentScale="dashboard.scale"/>
          </div>
        </div>
        <PaymentInsights />
      </vs-card>
    </div>
    <div class="mt-4 mb-2">
      <h4 class="text-muted">Recent Activities</h4>
    </div>
    <div>
      <vs-card class="cardx p-2">
        <div slot="header" class="pb-5">
          <div class="float-left">
            <h3>Transactions Summary</h3>
            <h5 class="text-muted font-weight-normal mt-1">
              Overview of This Month - {{month}}
            </h5>
          </div>
          <div class="float-right d-none d-md-block">
            <ChartDuration :currentScale="dashboard.scale"/>
          </div>
        </div>
        <EarningStat />
      </vs-card>
    </div>
    
  </div>
</template>

<script>
import EarningStat from "./components/dashboard/EarningStat";
import DashboardInfo from "./components/custom/DashboardInfo";
import ChartDuration from "./components/custom/ChartDuration";
import PaymentInsights from "./components/dashboard/PaymentInsights"
import { mapState } from 'vuex';
import { NAIRA } from '../utils/constants'

export default {
  name: "Dashboard",
  components: { EarningStat, DashboardInfo, ChartDuration, PaymentInsights },
  data() {
    return {
      prompt: false,
    };
  },
  computed: {
    ...mapState(['user', 'dashboard']),
    isLive() {
      return this.user ? this.user.environment === 'live' : false;
    },
    isAccountActivated() {
      return this.user ? this.user.account_activated : false;
    },
    month() {
      return new Date().toLocaleString('default', {month: 'long'});
    },
    walletBalance() {
      return NAIRA + this.dashboard.wallet_balance;
    },
    totalIncome() {
      return NAIRA + this.dashboard.total_income;
    },
    totalSettlement() {
      return NAIRA + this.dashboard.total_settlements;
    }
  },
  methods: {
    activate() {
      this.$router.push('/app/activation');
    },
  }
};
</script>

<style lang="scss" scoped>
    .top-card {
        height: 250px;
        .vs-col {
            margin-top: 80px;
        }
    }

    .circle {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: gray;
        position: absolute;
    }

    .center-icon {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, 50%);
    }

    .top-icon {
        border-radius: 50%;
        height: 45px;
        width: 45px;
        text-align: center;
        background-color: #ffffff;
        box-shadow: 0px 3px 10px 0px rgba(0,0,0,.1);
        cursor: default;
    }

    .flat-card {
      background-color: #fff;
      padding: 15px;
      // background-image: linear-gradient(to right, white, #492540);
    }
</style>