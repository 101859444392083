<template>
  <div class="mt-2">
    <vs-row class="align-items-center">
        <vs-col class="pl-3 my-4" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12" code-toggler>
            <div>
                <h1>{{naira}}{{dashboard.current_month_earnings.toLocaleString()}}</h1>
                <h6 class="text-muted font-weight-normal">Current Month Earnings</h6>
                <h3 class="mt-4">{{dashboard.current_month_transactions.toLocaleString()}}</h3>
                <h6 class="text-muted font-weight-normal">Current Month Transactions</h6>
                <vs-button class="mt-4" size="large">Last Month Summary</vs-button>
            </div>
        </vs-col>
        <div class="d-flex d-sm-none pt-2 mb-4">
          <ChartDuration :currentScale="scale"/>
        </div>
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="12" id="chartContainer" class="vs-con-loading__container">
          <div v-if="dashboard.chart_data.length == 0" class="d-flex flex-column justify-content-center align-items-center no-data">
            <h5>No Data Available</h5>
            <p class="text-muted">Tip: Try a different filter or date range.</p>
          </div>
            <Chart :styles="chartStyles" :chart-data="dashboard.chart_data" :label="dashboard.chart_label" :x-axes-scale="scale"/>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Chart from './TransactionsChart';
import ChartDuration from '../custom/ChartDuration'
import { mapState, mapActions, mapGetters } from 'vuex';
import { NAIRA } from '../../../utils/constants'

export default {
  name: "EarningStat",
  components: { Chart, ChartDuration },
  //props: ['scale'],
  mounted() {
    this.loadChartData(this.dashboard.scale);
  },
  computed: {
    ...mapState(['user', 'dashboard']),
    ...mapGetters({scale: 'getScale'}),
    chartStyles () {
      return {
        height: '250px',
        width: '100%',
        position: 'relative',
      }
    },
    naira() {
      return NAIRA;
    }
  },
  methods: {
    ...mapActions(['loadTransactions']),
    showLoading() {
      this.$vs.loading({
          text: 'Loading...',
          //type: 'point',
          container: '#chartContainer'
      });
    },
    closeLoading() {
      this.$vs.loading.close('#chartContainer > .con-vs-loading');
    },
    loadChartData(scale) {
      this.showLoading();
      let payload = {
        type: scale,
        close: () => {
          this.closeLoading();
        }
      }
      this.loadTransactions(payload);
    },
    scaleUpdate(scale) {
      this.loadChartData(scale);
    }
  },
  watch: {
    scale: function(newValue,) {
      window.console.log('New value gotten:', newValue);
      this.loadChartData(newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
  .no-data {
    height: 250px;
    position: absolute;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.7);
    z-index: 2;
  }
</style>
