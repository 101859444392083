<template>
    <vs-row class="align-items-center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4" code-toggler>
            <vs-icon size="40px" :icon="icon" :color="color"></vs-icon>
        </vs-col>
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8" code-toggler>
            <span class="text-dark">{{title}}</span>
            <h3>{{text}}</h3>
        </vs-col>
    </vs-row>
</template>

<script>
export default {
    name: 'DashboardInfo',
    props: ['icon', 'title', 'text', 'color']
}
</script>