<template>
  <div class="mt-2">
    <div id="chartContainer" class="d-flex flex-column vs-con-loading__container">
        <div v-if="dashboard.insight_data.length == 0" class="d-flex flex-column justify-content-center align-items-center no-data">
            <h5>No Data Available</h5>
            <p class="text-muted">Tip: Try a different filter or date range.</p>
        </div>
        <InsightsChart :styles="chartStyles" :chart-data="dashboard.insight_data" :label="dashboard.insight_label"/>
    </div>
  </div>
</template>

<script>
import InsightsChart from './InsightsChart';
import { mapState } from 'vuex';

export default {
    name: 'PaymentInsights',
    components: { InsightsChart },
    computed: {
        ...mapState(['dashboard'])
    },
    data() {
        return {
            chartStyles: {
                height: '350px',
                width: '100%',
                position: 'relative',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  .no-data {
    height: 350px;
    position: absolute;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.7);
    z-index: 2;
  }
</style>