<template>
    <div>
        <vs-button @click="update('hour')" :type="currentScale === 'hour' ? 'filled':'filled'" :color="currentScale === 'hour' ? '#F26D5B':'primary'">Hourly</vs-button>
        <vs-button @click="update('day')" :type="currentScale === 'day' ? 'filled':'filled'" :color="currentScale === 'day' ? '#F26D5B':'primary'">Daily</vs-button>
        <vs-button @click="update('week')" :type="currentScale === 'week' ? 'filled':'filled'" :color="currentScale === 'week' ? '#F26D5B':'primary'">Weekly</vs-button>
        <vs-button @click="update('month')" :type="currentScale === 'month' ? 'filled':'filled'" :color="currentScale === 'month' ? '#F26D5B':'primary'">Monthly</vs-button>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'ChartDuration',
    props: ['currentScale'],
    methods: {
        ...mapMutations(['updateDashboard']),
        update(scale) {
            this.xAxesScale = scale;
            this.$emit('scale', scale);
            this.updateDashboard({scale: scale});
        }
    }
}
</script>

<style lang="scss" scoped>
  .vs-button {
    border-radius: 0px;
  }
</style>