let NAIRA = '₦';

// let d = [
//     {
//         day: 'a',
//         am: 20
//     },
//     {
//         day: 'a',
//         am: 20
//     },
//     {
//         day: 'b',
//         am: 20
//     },
//     {
//         day: 'b',
//         am: 20
//     },
//     {
//         day: 'b',
//         am: 20
//     },
//     {
//         day: 'c',
//         am: 15
//     },
//     {
//         day: 'c',
//         am: 20
//     },
//     {
//         day: 'd',
//         am: 20
//     }
// ];

// let pp = d.reduce((a, {day, am}, i, arr) => {
//     let it = arr.find(el => el.day === day);
//     if (!it) return [...a, {day, am: [am]}];
//     it.am = it.am + am;
//     return a;
// },);

// let p = d.reduce((a, {day, am}) => {
//     a[day] = (a[day] || 0) + am;
//     return a;
// }, {});
// const [kk, vv] = Object.entries(p);
// console.log('Precessed:', p, Object.entries(p));

module.exports = { NAIRA }